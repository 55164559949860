import { createContext, useState, useEffect, useContext } from 'react';

import {
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
} from '@solana/web3.js';

import {ConnectionContext} from './connection'
import {useWallet} from './wallet'

import {
  findOrCreateAssociatedTokenAccount,
} from './web3'

export const AccessContext = createContext()

const AccessContextProvider = (props) => {
  const {wallet, connected} = useWallet()
  const {connection} = useContext(ConnectionContext)

  const [hasAccess, setHasAccess] = useState(false);
  const [claimTokenAccount, setClaimTokenAccount] = useState(undefined)
  const [faucet, setFaucet] = useState(undefined);
  const [program, setProgram] = useState(undefined);

  const {
    checkHasAccess,
    claimToken,
  } = accessContextHelper(
    hasAccess,
    setHasAccess,
    faucet,
    setFaucet,
    program,
    setProgram,
    claimTokenAccount,
    setClaimTokenAccount,
    connection,
    wallet,
  )

  useEffect(() => {
    if (connected) {
      checkHasAccess()
    } else {
      setHasAccess(false)
    }
  },[connected, connection, checkHasAccess])

  // useEffect(() => {
  //   getFaucetInfo()
  // }, [])

  return (
    <AccessContext.Provider
      value={{
        hasAccess,
        claimToken,
        faucet,
      }}
    >
      {props.children}
    </AccessContext.Provider>
  );
}

export default AccessContextProvider

const accessContextHelper = (
  hasAccess,
  setHasAccess,
  faucet,
  setFaucet,
  program,
  setProgram,
  claimTokenAccount,
  setClaimTokenAccount,
  connection,
  wallet,
) => {

  const checkHasAccess = async () => {
    if (wallet?.connected) {
      try {
        let tokenAccounts = await connection.getParsedTokenAccountsByOwner(
          wallet.publicKey,
          {mint: faucet.claimMint}
        )
        if (tokenAccounts.value.length > 0) {
          tokenAccounts.value.forEach(account => {
            if (account.account.data.parsed.info.tokenAmount.uiAmount > 0) {
              setHasAccess(true)
            } else {
              setClaimTokenAccount(account.account.pubkey)
            }
          })
        } 
      } catch (e) {
        console.log('error: ', e)
        return
      }
    } else {
      console.log('wallet not connected')
      return
    }
  }

  const claimToken = async () => {

    let userClaimTokenAccount = claimTokenAccount

    // console.log('connection', connection);
    // console.log('wallet', connection);
    // console.log('SystemProgram.programId', SystemProgram.programId);
    // console.log('SYSVAR_RENT_PUBKEY', SYSVAR_RENT_PUBKEY);
    // console.log('faucet.claimMint', faucet.claimMint);
    //let TOKEN = new PublicKey('F1GRpR7Ft7R97E12tpgi6uA1ar2oMYRZzKJQSDYAmeM1');
    let TOKEN = new PublicKey('E1PvPRPQvZNivZbXRL61AEGr71npZQ5JGxh4aWX7q9QA');

    try {
      userClaimTokenAccount = await findOrCreateAssociatedTokenAccount(
        connection,
        wallet,
        SystemProgram.programId,
        SYSVAR_RENT_PUBKEY,
        TOKEN
      )

      console.log("userClaimTokenAccount", userClaimTokenAccount);
      localStorage.setItem('claimed', true);
    } catch(err) {
      let msg = err.message;
      if(msg.includes("found no record of a prior credit")) {
        msg += "\n\nHint: You may own less than the amount required to create a token account: 0.002039 SOL (~$0.08 on May 7th)"
      }
      alert(msg);
    } 
  }


  return {
    checkHasAccess,
    claimToken
  }
}  
